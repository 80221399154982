<template>
  <div>
    <el-checkbox v-model="localValue" name="type" class="terms-checkbox">
      I accept CitronWorks
      <el-button type="text" class="terms" @click="showingTermsOfServiceModal = true">Terms of Service</el-button>
      <el-dialog
        :visible.sync="showingTermsOfServiceModal"
        width="90%"
      >
        <span slot="title" class="dialog-title">
          <h4 class="bold orange-text">Terms of Service</h4>
        </span>
        <terms-of-service />
        <span slot="footer" class="dialog-footer text-center w-100 d-block">
          <el-button type="warning" @click="showingTermsOfServiceModal = false">Close</el-button>
        </span>
      </el-dialog>
      and
      <br class="visible-tiny"><el-button type="text" class="terms" @click="showingPrivacyPolicyModal = true">Privacy Policy</el-button>
      <el-dialog :visible.sync="showingPrivacyPolicyModal" width="90%">
        <span slot="title" class="dialog-title">
          <h4 class="bold orange-text">Privacy Policy</h4>
        </span>
        <privacy-policy />
        <span slot="footer" class="dialog-footer text-center w-100 d-block">
          <el-button type="warning" @click="showingPrivacyPolicyModal = false">Close</el-button>
        </span>
      </el-dialog>.
    </el-checkbox>
  </div>
</template>

<script>
import TermsOfService from '~/components/Terms/TermsOfService.vue'
import PrivacyPolicy from '~/components/Terms/PrivacyPolicy.vue'

export default {
  components: {
    TermsOfService,
    PrivacyPolicy,
  },

  props: {
    value: {
      type: Boolean,
    },
  },

  data () {
    return {
      showingTermsOfServiceModal: false,
      showingPrivacyPolicyModal: false,
    }
  },

  computed: {
    localValue: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>

