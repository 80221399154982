import moment from 'moment'

export default (type, token) => {
  const types = ['freelancer', 'employer']
  const otherTypes = types.filter(t => t !== type)

  // remove onboarding tokens from other types
  otherTypes.forEach(ot => localStorage.removeItem(`${ot}:signup`))

  localStorage.setItem(`${type}:signup`, JSON.stringify({
    token,
    expiry: moment().add(2, 'hours').unix(),
  }))
}
