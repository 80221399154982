import setOnboardingToken from '~/helpers/setOnboardingToken'
import { Cookies } from '~/modules/cookies'

export default {
  data () {
    return {
      proceeding: false,
    }
  },

  computed: {
    hasCreated () {
      return this.SignUp.id === 'my'
    },

    signup () {
      return this.SignUp.data
    },
  },

  methods: {
    next () {
      this.proceeding = true
      this.save().then(() => {
        this.proceeding = false
        this.$emit('next')
      }).catch(() => {
        this.proceeding = false
      })
    },

    saveIfUpdate () {
      if (this.hasCreated) {
        this.save()
      }
    },

    save () {
      return new Promise((resolve, reject) => {
        const SignUp = this.SignUp

        // if just creating, add ref to the data
        if (! this.hasCreated && ! this.signup.ref) {
          SignUp.data.ref = Cookies.read('ref') || null
        }

        // save
        SignUp.save({ successMessage: false })
          .then(response => {
            // if this was a create request, it will respond a token
            if (! SignUp.id) {
              // store the token
              setOnboardingToken(this.type, response.data)
            }

            // clear error messages
            SignUp.errors = {}

            // set ID to 'my' to signal that the backend should authenticate
            // the token from now on
            SignUp.id = 'my'

            // rebuild the axios instance for the SignUp model to
            // include the token in its header on future requests
            SignUp.buildAxios()

            // proceed to the next tab if next flag is set to true
            resolve(response)
          })
          .catch(reject)
      })
    },
  },
}
