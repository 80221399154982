<template>
  <v-select class="countries-select"
    label="name"
    :components="{OpenIndicator}"
    :options="countries"
    :reduce="country => country.code"
    v-model="localValue"
  />
</template>

<script>
  import vSelect from 'vue-select'
  import 'vue-select/dist/vue-select.css'

  export default {
    components: { vSelect },

    props: {
      value: {
        type: String,
        default: '',
      },
    },

    data () {
      return {
        countries: require('~/data/countries').default,
        OpenIndicator: {
          render: createElement => createElement('span', {class: 'el-icon-arrow-down'}),
        },
      }
    },

    computed: {
      localValue: {
        get () {
          return this.value
        },

        set (value) {
          this.$emit('input', value)
        }
      },
    },
  }
</script>

<style lang="scss">
.countries-select {
  .vs__dropdown-toggle {
    height: 40px;
    border: 1px solid #DCDFE6;
  }
  .vs__dropdown-toggle:focus, .vs__dropdown-toggle:active, &.vs--open .vs__dropdown-toggle {
    border: 1px solid #E87722 !important;
  }
  .vs__dropdown-option {
    // width:100%;
    margin: 0;
    // padding: 5px 15px;
    // color: #606266;
    font-size: 14px;
    padding: 0 20px;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #606266;
    height: 34px;
    line-height: 34px;
    font-weight: 400;
  }
  .vs__actions {
    padding-right: 10px;
  }
  .vs__dropdown-option--highlight {
    background: #f3f3f3;
    color: #333;
  }
  .vs__clear {
    display: none !important;
  }
  .el-icon-arrow-down.vs__open-indicator {
    color: #C0C4CC;
  }
  .vs--open .vs__open-indicator, .el-select .el-input .el-select__caret {
    transform: none !important;
  }
  ::-webkit-scrollbar-thumb {
    background: red;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
  }
}

</style>