import { userFeedPath } from '~/vuex/utils/authentication'
import getToken from '~/helpers/getToken'

export default (user, type) => {
  user.provider = type
  user.authorization = {}
  user.authorization.access_token = getToken()
  localStorage.removeItem(`${type}:signup`)
  localStorage.setItem('user', JSON.stringify(user))
  location.href = userFeedPath()
}
